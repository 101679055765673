import Color from 'color';
import colorString from 'color-string';

export const isColorLight = (hexColor, defaultColor = '#000000') => {
    const bgColor = colorString.get(hexColor) ? Color(hexColor) : Color(defaultColor);
    return bgColor.isLight();
};

export const getBlackOrWhiteTextColorBasedOnBgColor = (hexColor, defaultColor = '#000') => {
    return isColorLight(hexColor, defaultColor) ? '#000000' : '#ffffff';
};

export const getRGB = (backgroundColor) =>
    backgroundColor
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16));

export const getRGBa = (backgroundColor, alpha) => {
    let rgba;
    const rgb = backgroundColor
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16));

    if (rgb) {
        rgba = `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${alpha})`;
    }

    return rgba;
};

export const getColorShade = (backgroundColor) => {
    const rgb = getRGB(backgroundColor);

    let light = true;

    if (rgb) {
        const r = 0.299 * (rgb[0] * rgb[0]);
        const g = 0.587 * (rgb[1] * rgb[1]);
        const b = 0.114 * (rgb[2] * rgb[2]);

        const hsp = Math.sqrt(r + g + b);

        if (hsp > 165) {
            light = false;
        }
    }
    return light;
};

export const rgbToHex = (r, g, b) =>
    `#${[r, g, b]
        .map((x) => {
            const hex = x.toString(16);
            return hex.length === 1 ? `0${hex}` : hex;
        })
        .join('')}`;

export const randomColorPastel = (type) => {
    const rand = Math.floor(Math.random() * 10);
    switch (type) {
        case 'hsl':
            return `hsl(${360 * Math.random()},${25 + 70 * Math.random()}%,${85 + 10 * Math.random()}%)`;
        case 'rgb':
            return `rgb(${215 - rand * 3},${185 - rand * 5},${185 - rand * 10} )`;
        case 'hex': {
            const r = (Math.round(Math.random() * 127) + 127).toString(16);
            const g = (Math.round(Math.random() * 127) + 127).toString(16);
            const b = (Math.round(Math.random() * 127) + 127).toString(16);
            return `#${r}${g}${b}`;
        }
        default:
            return `rgb(${Math.round(Math.random() * 127) + 127},${Math.round(Math.random() * 127) + 127},${
                Math.round(Math.random() * 127) + 127
            } )`;
    }
};

export const randomColor = (type) => {
    const rand = Math.floor(Math.random() * 10);
    switch (type) {
        case 'hsl':
            return `hsl(${Math.random() * 360}, 100%, 50%)`;
        case 'rgb':
            return `rgb(${Math.round(Math.random() * 255)},${Math.round(Math.random() * 255)},${Math.round(
                Math.random() * 255
            )})`;
        case 'hex': {
            return `#${(((1 << 24) * Math.random()) | 0).toString(16)}`;
        }
        default:
            return `rgb(${215 - rand * 3},${185 - rand * 5},${185 - rand * 10} )`;
    }
};

export const randomColorDark = (type) => {
    const rand = Math.floor(Math.random() * 10);
    switch (type) {
        case 'hsl':
            return `hsl(${360 * Math.random()},${25 + 70 * Math.random()}%, ${10 + 20 * Math.random()}%)`;
        case 'rgb':
            return `rgb(${115 - rand * 3},${85 - rand * 5},${85 - rand * 10} )`;
        case 'hex': {
            const r = Math.round(Math.random() * 70 + Math.random() * 100).toString(16);
            const g = Math.round(Math.random() * 70 + Math.random() * 100).toString(16);
            const b = Math.round(Math.random() * 70 + Math.random() * 100).toString(16);
            return `#${r}${g}${b}`;
        }
        default:
            return `rgb(${115 - rand * 3},${85 - rand * 5},${85 - rand * 10} )`;
    }
};

export const randomBlueColor = () => {
    const d = Math.round(Math.random()) * 10 + 10;
    const r = 60;
    const b = r + ((d * 7) % (226 - r));
    return `rgb(${r},${60 + r},${b})`;
};

export default getColorShade;
