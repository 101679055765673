/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';

const AgentAvatar = ({
    initials,
    agentProfilePicture,
    agentAlt,
    classNameForProfilePicture,
    classNameForInitials,
    agentSize,
    agentStyle,
    agentAvatarIcon,
}) => (
    <>
        {agentProfilePicture ? (
            <Avatar
                src={agentProfilePicture}
                alt={agentAlt}
                size={agentSize}
                style={agentStyle}
                className={classNameForProfilePicture}
                icon={agentAvatarIcon || <UserOutlined />}
            />
        ) : (
            <Avatar style={agentStyle} size={agentSize} className={classNameForInitials}>
                {initials}
            </Avatar>
        )}
    </>
);

AgentAvatar.propTypes = {
    initials: PropTypes.string,
    agentProfilePicture: PropTypes.string,
    agentAlt: PropTypes.string,
    classNameForProfilePicture: PropTypes.string,
    agentAvatarIcon: PropTypes.string,
    classNameForInitials: PropTypes.string,
    agentSize: PropTypes.any,
    agentStyle: PropTypes.object,
};

AgentAvatar.defaultProps = {
    initials: '',
    agentProfilePicture: null,
    agentAlt: '',
    classNameForProfilePicture: '',
    agentAvatarIcon: null,
    classNameForInitials: '',
    agentSize: 'default',
    agentStyle: {},
};

export default AgentAvatar;
