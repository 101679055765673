import { useEffect, useState } from 'react';

type Result = {
    lat: number;
    lng: number;
    ipApiData: any;
};

export default function useIpApi(): Result {
    const [lat, setLat] = useState<number>(null);
    const [lng, setLng] = useState<number>(null);
    const [ipApiData, setIpApiData] = useState(null);

    useEffect(() => {

        const controller = new AbortController();
        const { signal } = controller;

        fetch(`${process.env.NEXT_PUBLIC_IP_API_URL}&fields=54063103`, { signal })
            .then((resp) => resp.json())
            .then((data) => {
                setLat(data?.lat);
                setLng(data?.lon);
                setIpApiData(data);
            })
            .catch((err) => {
                if (err.name === 'AbortError') {
                    console.log('successfully aborted');
                } else {
                    console.log(err);
                }
            });
        return () => {
            // cancel the request before component unmounts
            controller.abort();
        };
    }, []);

    return {
        lat,
        lng,
        ipApiData,
    };
}
