/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { Media, MediaContextProvider } from '~/Media';
import AgentAvatar from '~/components/AgentAvatar';
import { trackEvent } from '~/lib/analytics/index.ts';
import { getColorShade } from '~/utils/colors';
import AgentPropertyLiveStream from './Operations.graphql';
import styles from './AgentWithLive.module.scss';

const AgentWithLive = (props) => {
    const {
        // agentIds,
        agent,
        agency,
        activeLink,
        bannerColor,
        propertyId,
        withLiveStyle,
        mainAgent,
        // isFromFeaturedPropertyCard,
        isFromAgentPage,
        isFromAgentEnquiry,
        isFromAgentEnquiryFooter,
        isFromAgencyPage,
        profilePhoto,
        isInitialFromAgencyPage,
        agencyAgentsSlugUrl,
        isFromAgentBar,
        isFromAgentBarMobile,
    } = props;

    // const id = agentIds || {};
    const { id, initials, firstName, lastName, profilePicture, slugUrl: agentSlugUrl } = agent || {};

    const { name } = agency || {};

    const { data } = useQuery(AgentPropertyLiveStream, {
        // ssr: false,
        notifyOnNetworkStatusChange: true,
        pollInterval: 30000,
        variables: { id },
        skip: !id,
    });

    const { propertyLiveStreamsConnection } = data?.agent ?? {};
    const { nodes } = propertyLiveStreamsConnection || {};
    const isLive = propertyLiveStreamsConnection?.nodes?.length > 0;
    let uuid;
    nodes?.map((agentUuid) => {
        // eslint-disable-next-line no-return-assign
        if (isLive) return (uuid = agentUuid?.uuid);
    });

    const createAgentClickProfileEvent = () => {
        trackEvent('Clicked Agent Profile', {
            dimension_1: parseInt(id, 10),
            dimension_2: parseInt(propertyId, 10),
        });
    };

    const shade = bannerColor && bannerColor !== '#fff' && getColorShade(bannerColor);
    const textClass = shade
        ? {
              main: 'text-shadow-sm text-white',
              sub: 'text-shadow-sm text-gray-200',
          }
        : {
              main: 'text-gray-900',
              sub: 'text-gray-700',
          };

    return (
        <div
            className={
                mainAgent || isFromAgentPage || isFromAgentEnquiry || isFromAgentEnquiryFooter || isFromAgencyPage
                    ? 'flex cursor-pointer justify-center'
                    : 'mr-10 flex cursor-pointer'
            }
        >
            {agencyAgentsSlugUrl ? (
                <Link href={activeLink && isLive ? `/live/${uuid}` : `/agent/${agencyAgentsSlugUrl}`} prefetch={false}>
                    <a target="_blank">
                        <div className="flex items-center" onClick={createAgentClickProfileEvent} aria-hidden>
                            {!isFromAgentPage &&
                                !mainAgent &&
                                !isFromAgentEnquiry &&
                                !isFromAgentEnquiryFooter &&
                                isFromAgencyPage && (
                                    <AgentAvatar
                                        agentProfilePicture={profilePhoto ? `${profilePhoto}?w=300&auto=format` : null}
                                        initials={isInitialFromAgencyPage}
                                        agentAlt={`${firstName || ''} ${lastName || ''}`}
                                        agentSize={150}
                                        classNameForProfilePicture={clsx(`${styles['agent-avatar']}`, {
                                            'border-red-600 border-4': isLive,
                                            'border-gray-400 border-2': !isLive,
                                        })}
                                        classNameForInitials={`border-2 border-gray-400 ${styles['agent-profile-picture-property']}`}
                                    />
                                )}
                            {!mainAgent && isFromAgentPage && !isFromAgentEnquiry && !isFromAgentEnquiryFooter && (
                                // <ScreenWidthValueProvider xs={200} sm={200} md={200} lg={250} xl={250} xxl={250}>
                                // {width => (
                                <MediaContextProvider>
                                    <Media lessThan="md">
                                        <AgentAvatar
                                            agentAlt={`${firstName || ''} ${lastName || ''} ${name || ''} Agent`}
                                            agentProfilePicture={profilePicture || null}
                                            agentSize={200}
                                            initials={initials}
                                            classNameForProfilePicture={clsx(`${styles['agent-avatar']}`, {
                                                'border-red-600 border-4': isLive,
                                                'border-gray-400 border-2': !isLive,
                                            })}
                                            classNameForInitials={`border-2 border-gray-400 ${styles['agent-profile-picture-property']}`}
                                            agentStyle={{ borderColor: bannerColor }}
                                        />
                                    </Media>
                                    <Media greaterThanOrEqual="md">
                                        <AgentAvatar
                                            agentAlt={`${firstName || ''} ${lastName || ''} ${name || ''} Agent`}
                                            agentProfilePicture={profilePicture || null}
                                            agentSize={250}
                                            initials={initials}
                                            classNameForProfilePicture={clsx(`${styles['agent-avatar']}`, {
                                                'border-red-600 border-4': isLive,
                                                'border-gray-400 border-2': !isLive,
                                            })}
                                            classNameForInitials={`border-2 border-gray-400 ${styles['agent-profile-picture-property']}`}
                                            agentStyle={{ borderColor: bannerColor }}
                                        />
                                    </Media>
                                </MediaContextProvider>
                            )}
                            {isFromAgencyPage ? null : (
                                <div className={`ml-3 hidden text-left text-lg font-medium xl:block ${textClass.main}`}>
                                    {firstName || ''} {lastName || ''}
                                </div>
                            )}
                            {isLive ? <span className={withLiveStyle}>LIVE</span> : null}
                        </div>
                    </a>
                </Link>
            ) : (
                <Link href={activeLink && isLive ? `/live/${uuid}` : `/agent/${agentSlugUrl}`} prefetch={false}>
                    <a target="_blank">
                        <div className="flex items-center" onClick={createAgentClickProfileEvent} aria-hidden>
                            {isFromAgentPage &&
                                !mainAgent &&
                                isFromAgentEnquiry &&
                                !isFromAgentEnquiryFooter &&
                                !isFromAgentBar &&
                                !isFromAgentBarMobile && (
                                    <AgentAvatar
                                        agentAlt={`${firstName || ''} ${lastName || ''} ${name || ''} Agent`}
                                        agentProfilePicture={profilePicture || null}
                                        agentSize={200}
                                        initials={initials}
                                        classNameForProfilePicture={clsx(`${styles['agent-avatar']}`, {
                                            'border-red-600 border-4': isLive,
                                            'border-gray-400 border-2': !isLive,
                                        })}
                                        classNameForInitials={`border-2 border-gray-400 ${styles['agent-profile-picture-property']}`}
                                        agentStyle={{ borderColor: bannerColor }}
                                    />
                                )}
                            {!isFromAgentPage &&
                                !mainAgent &&
                                !isFromAgentEnquiry &&
                                !isFromAgentEnquiryFooter &&
                                !isFromAgencyPage &&
                                isFromAgentBar && (
                                    <AgentAvatar
                                        agentProfilePicture={
                                            profilePicture ? `${profilePicture}?w=100&auto=format` : null
                                        }
                                        initials={initials}
                                        agentAlt={`${firstName || ''} ${lastName || ''}`}
                                        classNameForProfilePicture={clsx(`${styles['agent-avatar']}`, {
                                            'border-red-600 border-4': isLive,
                                            'border-gray-400 border-2': !isLive,
                                        })}
                                        classNameForInitials={`border-2 border-gray-400 ${styles['agent-profile-picture-property']}`}
                                    />
                                )}
                            {!isFromAgentPage &&
                                !mainAgent &&
                                !isFromAgentEnquiry &&
                                !isFromAgentEnquiryFooter &&
                                !isFromAgencyPage &&
                                !isFromAgentBar &&
                                isFromAgentBarMobile && (
                                    <AgentAvatar
                                        agentProfilePicture={
                                            profilePicture ? `${profilePicture}?w=100&auto=format` : null
                                        }
                                        initials={initials}
                                        // agentSize={200}
                                        agentAlt={`${firstName || ''} ${lastName || ''}`}
                                        // applies here
                                        classNameForProfilePicture={clsx(`${styles['agent-avatar']}`, {
                                            'border-red-600 border-4': isLive,
                                            'border-gray-400 border-2': !isLive,
                                        })}
                                        classNameForInitials={`border-2 border-gray-400 ${styles['agent-profile-picture-property']}`}
                                    />
                                )}
                            {!isFromAgentPage &&
                                !mainAgent &&
                                !isFromAgentEnquiry &&
                                isFromAgentEnquiryFooter &&
                                !isFromAgentBar &&
                                !isFromAgentBarMobile && (
                                    <AgentAvatar
                                        agentAlt={`${firstName || ''} ${lastName || ''} ${name || ''} Agent`}
                                        agentProfilePicture={profilePicture || null}
                                        initials={initials}
                                        agentSize={100}
                                        classNameForProfilePicture={clsx(`${styles['agent-avatar']}`, {
                                            'border-red-600 border-4': isLive,
                                            'border-gray-400 border-2': !isLive,
                                        })}
                                        classNameForInitials={`border-2 border-gray-400 ${styles['agent-profile-picture-property']}`}
                                        agentStyle={{ borderColor: bannerColor }}
                                    />
                                )}
                            {!isFromAgentPage && mainAgent && !isFromAgentEnquiry && !isFromAgentEnquiryFooter && (
                                <AgentAvatar
                                    agentProfilePicture={profilePicture ? `${profilePicture}?w=150&auto=format` : null}
                                    initials={initials}
                                    agentAlt={`${firstName || ''} ${lastName || ''}`}
                                    agentSize={100}
                                    classNameForProfilePicture={clsx(`${styles['agent-avatar']}`, {
                                        'border-red-600 border-4': isLive,
                                        'border-gray-400 border-2': !isLive,
                                    })}
                                    classNameForInitials={`border-2 border-gray-400 ${styles['agent-profile-picture-property']}`}
                                />
                            )}
                            {!mainAgent && isFromAgentPage && !isFromAgentEnquiry && !isFromAgentEnquiryFooter && (
                                <MediaContextProvider>
                                    <Media lessThan="md">
                                        <AgentAvatar
                                            agentAlt={`${firstName || ''} ${lastName || ''} ${name || ''} Agent`}
                                            agentProfilePicture={profilePicture || null}
                                            agentSize={200}
                                            initials={initials}
                                            classNameForProfilePicture={clsx(`${styles['agent-avatar']}`, {
                                                'border-red-600 border-4': isLive,
                                                'border-gray-400 border-2': !isLive,
                                            })}
                                            classNameForInitials={`border-2 border-gray-400 ${styles['agent-profile-picture-property']}`}
                                            agentStyle={{ borderColor: bannerColor }}
                                        />
                                    </Media>
                                    <Media greaterThanOrEqual="md">
                                        <AgentAvatar
                                            agentAlt={`${firstName || ''} ${lastName || ''} ${name || ''} Agent`}
                                            agentProfilePicture={profilePicture || null}
                                            agentSize={250}
                                            initials={initials}
                                            classNameForProfilePicture={clsx(`${styles['agent-avatar']}`, {
                                                'border-red-600 border-4': isLive,
                                                'border-gray-400 border-2': !isLive,
                                            })}
                                            classNameForInitials={`border-2 border-gray-400 ${styles['agent-profile-picture-property']}`}
                                            agentStyle={{ borderColor: bannerColor }}
                                        />
                                    </Media>
                                </MediaContextProvider>
                            )}
                            {mainAgent ||
                            isFromAgentPage ||
                            isFromAgentEnquiry ||
                            isFromAgentEnquiryFooter ||
                            !isFromAgentBar ||
                            isFromAgentBarMobile ? null : (
                                <div className={`ml-3 hidden text-left text-lg font-medium xl:block ${textClass.main}`}>
                                    {firstName || ''} {lastName || ''}
                                </div>
                            )}
                            {isLive ? <span className={withLiveStyle}>LIVE</span> : null}
                        </div>
                    </a>
                </Link>
            )}
        </div>
    );
};
AgentWithLive.propTypes = {
    agentIds: PropTypes.string,
    agent: PropTypes.object,
    agency: PropTypes.object,
    profilePhoto: PropTypes.string,
    propertyId: PropTypes.string,
    bannerColor: PropTypes.string,
    activeLink: PropTypes.bool,
    withLiveStyle: PropTypes.string,
    mainAgent: PropTypes.bool,
    isFromFeaturedPropertyCard: PropTypes.bool,
    isFromAgentPage: PropTypes.bool,
    isFromAgentEnquiry: PropTypes.bool,
    isFromAgentEnquiryFooter: PropTypes.bool,
    isFromAgentBarMobile: PropTypes.bool,
    isFromAgencyPage: PropTypes.bool,
    isFromAgentBar: PropTypes.bool,
    isInitialFromAgencyPage: PropTypes.string,
    agencyAgentsSlugUrl: PropTypes.string,
};

AgentWithLive.defaultProps = {
    agentIds: null,
    agent: {},
    agency: {},
    profilePhoto: null,
    propertyId: null,
    bannerColor: null,
    activeLink: true,
    withLiveStyle: '',
    mainAgent: false,
    isFromFeaturedPropertyCard: false,
    isFromAgentPage: false,
    isFromAgentEnquiry: false,
    isFromAgentEnquiryFooter: false,
    isFromAgencyPage: false,
    isFromAgentBar: false,
    isFromAgentBarMobile: false,
    isInitialFromAgencyPage: null,
    agencyAgentsSlugUrl: null,
};

export default AgentWithLive;
