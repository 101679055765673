import React, { memo, useState } from 'react';
import { useMutation } from '@apollo/client';
import useIpApi from '~/hooks/useIpApi';
import { trackEvent } from '~/lib/analytics';
import AgentContactNumberMutation from './Operations.graphql';

type Props = {
    useTel?: boolean;
    agentId: string;
    contactNo: string;
    className: string;
    propertyId: number | string;
};

const AgentContactNumber = memo((props: Props) => {
    const { contactNo = '', agentId = '', propertyId = '', className = '', useTel = false } = props || {};

    const [agentContactNumberMutation] = useMutation(AgentContactNumberMutation);

    const { ipApiData } = useIpApi();

    const parseNumber = contactNo ? contactNo.substring(0, 4) + '•'.repeat(contactNo.length - 4) : '';

    const [isFull, setIsFull] = useState(false);
    const [displayedNumber, setDisplayedNumber] = useState(parseNumber);

    const handleClick = () => {

        if (displayedNumber !== contactNo) {
            agentContactNumberMutation({
                variables: {
                    input: {
                        agentId,
                        propertyId,
                        type: 'NUMBER',
                        referrer: window.document.referrer || null,
                        pageUrl: window.location.href,
                        ipInfo: {
                            ipAddress: ipApiData?.query,
                            continent: ipApiData?.continent,
                            continentCode: ipApiData?.continentCode,
                            country: ipApiData?.country,
                            countryCode: ipApiData?.countryCode,
                            region: ipApiData?.region,
                            regionName: ipApiData?.regionName,
                            city: ipApiData?.city,
                            district: ipApiData?.district,
                            zip: ipApiData?.zip,
                            lat: ipApiData?.lat,
                            lon: ipApiData?.lon,
                            timezone: ipApiData?.timezone,
                            offset: ipApiData?.offset,
                            currency: ipApiData?.currency,
                            isp: ipApiData?.isp,
                            org: ipApiData?.org,
                            as: ipApiData?.as,
                            asname: ipApiData?.asname,
                            mobile: ipApiData?.mobile,
                        },
                    },
                },
            })
                .then(() => {
                    trackEvent('Clicked Agent Contact Number', {
                        dimension_1: parseInt(agentId, 10),
                        dimension_2: parseInt(propertyId.toString(), 10),
                    });

                    setDisplayedNumber(contactNo);
                    setIsFull(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <span onClick={handleClick} aria-hidden className={`cursor-pointer ${className}`}>
            {isFull ? (
                <>{useTel ? <a href={`tel:${displayedNumber}`}>{displayedNumber}</a> : displayedNumber}</>
            ) : (
                displayedNumber
            )}
        </span>
    );
});

export default AgentContactNumber;
