/* eslint-disable react/forbid-prop-types */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import clsx from 'clsx';
import { PhoneOutlined } from '@ant-design/icons';
import Link from 'next/link';
import dayjs from 'dayjs';

import { isColorLight } from '~/utils/colors';

import MainFeatures from '~/components/MainFeatures';
import PropertyAddress from '~/components/PropertyAddress';
import PropertyCarousel from '~/components/PropertyCarousel';
import PropertyPriceParse from '~/components/PropertyPriceParse';
import AgentWithLive from '~/components/AgentWithLive';
import usePropertyInView from '~/utils/usePropertyInView';
import HideListing from '~/components/HideListing';
import AgentContactNumber from '~/components/AgentContactNumber';

import styles from './FeaturedPropertyCardLg.module.scss';

const FeaturedPropertyCardLg = ({
    offsetHeaderHeight,
    property,
    ofiTime,
    isFromSearch,
    hasScrolled,
    collectionId,
    isFeed,
    sr,
    carouselImageHeight,
    isHomePage,
    showCardActions,
    isInfoBox,
    isPropertyPage,
    searchOptions,
    searchUrl,
    propertySearchVariables,
}) => {
    const { id, features, mainAgent, slugUrl, agency, rentDateAvailable } = property;

    const [isLight, setIsLight] = useState(false);

    let formatRentDateAvailable;
    const card = useRef(null);

    const isPropertyInView = usePropertyInView({
        offsetHeaderHeight,
        hasScrolled,
        property,
        card: card.current,
    });
    const { name, miniLogoUrl, bannerColor, logoUrl, slugUrl: agencySlugUrl } = agency || {};
    const { firstName, lastName, profilePicture } = mainAgent || {};
    const withLiveStyle =
        'absolute bottom-0 ml-3 text-white text-xs px-4 rounded-sm border border-white bg-primary-600';

    const currentDate = dayjs();

    if (rentDateAvailable) {
        formatRentDateAvailable = dayjs(rentDateAvailable);
    }

    useEffect(() => {
        if (bannerColor) {
            setIsLight(isColorLight(bannerColor));
        }
    }, [bannerColor]);

    return (
        <div className="h-full w-full" ref={card}>
            <div
                className={clsx(`border-21 ${styles.cardShadow} relative h-full w-full overflow-hidden bg-white`, {
                    'rounded-md': !isFeed,
                })}
                key={id}
            >
                <div className="relative h-16 p-1 text-right" style={{ background: bannerColor || '#2F3940' }}>
                    <div className="inline-block h-full w-1/2 max-w-max">
                        <Link href={`/agency/${agencySlugUrl}`}>
                            <a>
                                <img
                                    src={miniLogoUrl || logoUrl}
                                    alt={name}
                                    className="h-full cursor-pointer object-contain"
                                />
                            </a>
                        </Link>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div
                        className="relative w-full cursor-pointer overflow-hidden border-b-4"
                        style={{ borderColor: bannerColor || '#2F3940' }}
                    >
                        <PropertyCarousel
                            collectionId={collectionId}
                            property={property}
                            ofiTime={ofiTime}
                            height={carouselImageHeight}
                            isFromSearch={isFromSearch}
                            searchOptions={searchOptions || null}
                            searchUrl={searchUrl}
                            sr={sr}
                            isHomePage={isHomePage}
                            isPropertyInView={isPropertyInView}
                            showActions={showCardActions}
                            isInfoBox={isInfoBox}
                            ratio="60%"
                        />
                    </div>

                    <div className="property-card-info relative flex w-full justify-between p-3 sm:p-3">
                        <div className="w-2/3 pr-2 pl-5">
                            {/* eslint-disable-next-line max-len */}
                            <h4 className="text-overflow flex items-center text-left text-2xl font-medium text-gray-800">
                                <div className={`flex-1 ${isPropertyPage ? 'break-word' : 'text-overflow'}`}>
                                    <PropertyPriceParse
                                        className="text-left"
                                        property={property}
                                        rentBondClass="text-xs text-gray-700"
                                        isPropertyPage={isPropertyPage}
                                    />
                                </div>
                            </h4>

                            {/* eslint-disable-next-line max-len */}
                            <div className="text-overflow my-2 text-sm font-semibold leading-loose text-gray-700 xl:text-base">
                                <PropertyAddress
                                    property={property}
                                    newLine
                                    query={{
                                        isFromSearch,
                                        sr,
                                        searchOptions: searchOptions || null,
                                        searchUrl,
                                    }}
                                    isPropertyPage={isPropertyPage}
                                />
                            </div>
                            {/* rental date available */}
                            {formatRentDateAvailable ? (
                                formatRentDateAvailable <= currentDate ? (
                                    <div className="mb-2 text-sm font-light">Available Now</div>
                                ) : (
                                    <div className="mb-2 text-sm font-light">
                                        Available {dayjs(formatRentDateAvailable).format('DD MMM YYYY')}
                                    </div>
                                )
                            ) : null}
                            <div className="mb-2 text-left">
                                <MainFeatures
                                    features={features}
                                    displayText={!isPropertyPage}
                                    classNames={{
                                        feature: `rounded-full mr-2 text-[#718096] font-semibold text-base`,
                                    }}
                                />
                            </div>
                            <div className="mb-2 text-left">
                                <HideListing
                                    hideStatus="unhide"
                                    propertyId={id}
                                    propertySearchVariables={propertySearchVariables}
                                />
                            </div>
                        </div>

                        {mainAgent ? (
                            <div className="-mt-16 flex w-1/3 flex-col items-center text-center">
                                <div>
                                    <Link href={`/agent/${mainAgent?.slugUrl}`}>
                                        {/* eslint-disable-next-line max-len */}
                                        <a className="text-gray-800 no-underline focus:no-underline active:no-underline">
                                            <AgentWithLive
                                                key={mainAgent.id}
                                                agent={mainAgent}
                                                propertyId={property?.id ?? null}
                                                bannerColor={agency?.bannerColor ?? null}
                                                withLiveStyle={withLiveStyle}
                                                style={{
                                                    borderColor: bannerColor || '#2F3940',
                                                    background: bannerColor || '#2F3940',
                                                }}
                                                mainAgent
                                            />
                                        </a>
                                    </Link>
                                </div>
                                <div className="ml-41 flex flex-col justify-center">
                                    <Link href={`/agent/${mainAgent?.slugUrl}`}>
                                        <a className="no-underline focus:no-underline active:no-underline">
                                            <span className="text-lg font-semibold text-gray-800">
                                                {firstName} {lastName}
                                            </span>
                                        </a>
                                    </Link>
                                    {agency ? (
                                        <Link href={`/agency/${agency?.slugUrl}`}>
                                            {/* eslint-disable-next-line max-len */}
                                            <a className="inline-block text-gray-800 no-underline focus:no-underline active:no-underline">
                                                {name}
                                            </a>
                                        </Link>
                                    ) : null}
                                    {mainAgent.contactNo ? (
                                        <div className="text-[#718096]">
                                            <PhoneOutlined
                                                style={{ color: isLight ? 'black' : 'white' }}
                                                className="mr-1 text-sm"
                                            />
                                            <AgentContactNumber
                                                agentId={mainAgent?.id}
                                                contactNo={mainAgent?.contactNo}
                                                propertyId={id}
                                                className="text-sm text-[#718096]"
                                                useTel
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

FeaturedPropertyCardLg.propTypes = {
    collectionId: PropTypes.string,
    isFeed: PropTypes.bool,
    ofiTime: PropTypes.bool,
    isInfoBox: PropTypes.bool,
    property: PropTypes.shape({
        id: PropTypes.string,
        slugUrl: PropTypes.string,
        features: PropTypes.object,
        agents: PropTypes.array,
        agency: PropTypes.object,
        mainAgent: PropTypes.object,
    }),
    isFromSearch: PropTypes.bool,
    carouselImageHeight: PropTypes.string,
    sr: PropTypes.string,
    hasScrolled: PropTypes.bool,
    isHomePage: PropTypes.bool,
    showCardActions: PropTypes.bool,
    offsetHeaderHeight: PropTypes.number,
    isPropertyPage: PropTypes.bool,
    searchOptions: PropTypes.string,
    searchUrl: PropTypes.string,
};

FeaturedPropertyCardLg.defaultProps = {
    collectionId: '',
    isFeed: false,
    ofiTime: false,
    isInfoBox: false,
    property: {},
    isFromSearch: false,
    carouselImageHeight: '16rem',
    sr: '',
    hasScrolled: false,
    isHomePage: false,
    showCardActions: true,
    offsetHeaderHeight: 0,
    isPropertyPage: false,
    searchOptions: '',
    searchUrl: '',
};
export default FeaturedPropertyCardLg;
